const variantName = 'contentBoxesV2'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {},

    // ?============================
    // ?=========  Boxes  ==========
    // ?============================

    contentBoxes: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '0 auto',
      // ?== Psuedo Elements ==
      '& a:nth-of-type(2n+1) > div': {
        backgroundColor: 'primary'
      },
      '& a:nth-of-type(2n) > div': {
        backgroundColor: 'secondary'
      }
    },

    box: {
      display: 'flex',
      position: 'relative',
      flexGrow: 1,
      height: '300px'
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaName: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      height: '100%',
      width: '100%',
      fontSize: 8,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.4s',
      // ?== Psuedo Elements ==
      '&:hover': {
        backgroundColor: 'unset !important'
      }
    }
  }
}
