export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.navItem a': {
      ':hover': {
        color: 'primary'
      }
    }
  },

  '.navItem': {
    ':hover': {
      color: 'primary'
    }
  },

  footer: {
    '.columnLogo': {
      '.image': {
        // filter: 'brightness(0) invert(1)'
      }
    },
    '.bottomBar': {
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      width: '100%',

      '.gonationLogo': {
        a: {
          display: 'flex',
          justifyContent: ['center', '', 'flex-start']
        },
        order: '1'
      },
      '.copyright': {
        order: '2'
      }
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['3rem', '', '4rem'],
    textTransform: 'uppercase',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '1.5rem',
    color: 'white'
  },
  subtitle: {
    color: 'secondary',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['2rem', '', '3.5rem'],
    marginBottom: '1.5rem',
    '::before': {
      content: "''",
      backgroundColor: 'white',
      width: '10%',
      height: '5px'
    },
    '::after': {
      content: "''",
      backgroundColor: 'white',
      width: '10%',
      height: '5px'
    }
  },
  text: {
    textAlign: 'center',
    opacity: '0.8',
    fontSize: ['1.2rem'],
    p: {
      textAlign: 'center',
      fontSize: 'inherit'
    }
  },

  sideBySide: {
    overflow: 'hidden',
    padding: '1rem',
    margin: '3rem 0rem',
    '.lazyload-wrapper': {
      maxHeight: '75vh',
      transform: 'scale(1.1)'
    },
    '.content': {
      border: 'solid 2px',
      backgroundColor: 'black',
      alignItems: 'center',
      padding: ['3rem 1rem 4rem', '3rem 2rem 4rem', '3rem', '3.5rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: ['', '', 'calc(100vh + 30px)'],
    padding: ['100px 0rem 0rem', '', '0rem'],
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '3rem',
    overflow: 'hidden',
    '.lazyload-wrapper': {
      width: ['100%', '', '50%'],
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      right: ['', '', '-10%'],
      '::before': {
        content: "''",
        height: '55%',
        width: '120%',
        border: 'dashed 11px',
        position: 'absolute',
        top: '57%',
        left: '56%',
        transform: 'translate(-50%, -50%)'
      },
      '.image': {
        margin: 'auto',
        height: '55%',
        objectFit: 'cover',
        '-webkit-filter': 'drop-shadow(1px 12px 15px black)',
        filter: 'drop-shadow(1px 12px 15px black)',
        transform: 'rotate(-0.5deg)'
        // maxWidth: ['420px', '', '420px']
      }
    },

    '.content': {
      width: ['100%', '', '50%'],
      order: ['2', '', 'unset']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['3rem', '', '5rem', '6rem']
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text',
      fontSize: ['1.25rem', '', '1.5rem']
    }
  },

  homepageHeroShout: {},

  homepageShout: {
    p: '1rem',
    backgroundColor: 'background',
    margin: '0rem 0rem',
    '.title': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', '#ffffff8a'],
      fontSize: ['2.5rem', '', '3rem']
    },
    '.text': {
      fontFamily: 'Handy Casual Condensed',
      fontSize: ['2.5rem', '', '3rem'],
      order: ['4', '', '3'],
      // border: ['', '', 'solid 9px'],
      // borderColor: ['', '', 'black'],
      fontFamily: 'display',
      fontWeight: 'normal',
      padding: ['1rem', '', '1.5rem 2.5rem'],
      backgroundColor: ['black', '', '', 'secondary']
    },
    '.date': {
      backgroundColor: 'black',
      // border: ['', '', '', '#ffffff solid 9px'],
      // borderColor: ['', '', '', 'black'],
      fontSize: '0.8rem',
      fontWeight: 'bold',
      writingMode: ['', '', '', 'tb'],
      p: '1rem 0.5rem'
    },
    '.imageContainer': {
      // border: ['', '', '', 'solid 9px'],
      // borderColor: ['', '', '', 'black'],
      order: ['', '', '4']
    },
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide',
    'div.content': {
      order: ['2', '', 'unset']
    },
    mt: ['0rem', '', '200px']
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide'
  },

  homepageContact: {
    variant: 'customVariants.sideBySide',
    padding: '10vh 5vw',
    marginBottom: '0rem',
    '.text': {
      '*': {
        color: 'white',
        opacity: '1',
        fontWeight: 'bold'
      },
      textShadow: '2px 2px 8px black',
      fontSize: ['1.1rem', '', '1.25rem'],
      mt: '2rem',
      maxWidth: '500px'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
      }
    },

    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      textTransform: 'uppercase',
      color: 'light'
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '9rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    }
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem'],
      color: 'primary',
      marginBottom: '1.5rem',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.content_container': {
      backgroundColor: 'secondary'
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    }
  },

  // ? ========================
  // ? =====  Team pages  =====
  // ? ========================

  teamMenu: {
    marginTop: ['5rem', '', '8rem'],
    padding: ['0rem 1rem', '0rem 1rem', '0rem 1rem', '0rem 1rem'],
    '.boxesHeading': {
      display: 'none'
    },
    '.boxesContainer': {
      // flexDirection: 'column',
      maxWidth: '767px',
      margin: '0rem auto'
    },
    '.box': {
      width: ['', '', 'calc(50% - 1rem)'],
      ':hover': {},
      a: {
        // display: 'flex',
        // flexDirection: ['', '', 'row'],
      }
    },
    '.textContent': {
      position: 'static'
    }
  },
  teamPage: {
    '.imageContainer': {
      width: ['', '', '40%']
    },
    '.contentRow': {
      width: ['', '', '60%'],
      padding: ['1rem', '1.5rem', '2.5rem', '3rem']
    },
    '.title': {
      color: 'white'
    },
    '.subtitle': {
      color: 'primary'
    }
  }
}
