const variantName = 'contentSectionV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      position: 'relative',
      whiteSpace: 'break-spaces',
      color: 'text',
      paddingX: 7,
      paddingY: 10,
      backgroundColor: 'background',
      backgroundAttachment: ['', '', '', 'fixed'],
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    },
    section: {
      maxWidth: '767px',
      margin: '0 auto',
      flexDirection: 'column',
      textAlign: ['', '', 'center'],
      alignItems: ['center'],
      justifyContent: 'center'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      marginBottom: 3,
      fontSize: 8,
      textAlign: 'center'
    },
    subtitle: {
      marginBottom: 3,
      fontSize: 4,
      fontWeight: 'normal',
      textAlign: 'center'
    },
    text: {
      zIndex: '100',
      fontSize: [3, 3, 3, 3],
      lineHeight: '1.75',
      marginY: 3,
      textAlign: 'center',
      whiteSpace: 'break-spaces'
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      variant: 'buttons.primary',
      backgroundColor: 'primary'
    },
    secondaryCtaButton: {
      variant: 'buttons.secondary',
      backgroundColor: 'secondary'
    }
  }
}
