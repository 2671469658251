const variantName = 'contentSectionV1a'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      position: 'relative',
      whiteSpace: 'break-spaces',
      color: 'light',
      paddingX: ['1rem'],
      paddingY: ['3rem', '', '12rem'],
      backgroundColor: 'background',
      backgroundAttachment: ['', '', '', 'fixed'],
      backgroundPosition: '50%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      // ?== Psuedo Elements ==
      '::before': {
        content: "''",
        width: '50%',
        height: '100%',
        position: 'absolute',
        top: '0px',
        left: '0px',
        bottom: '0px',
        right: '50%',
        backgroundColor: 'primary',
        zIndex: '1'
      }
    },
    section: {
      maxWidth: '767px',
      margin: '0 auto',
      flexDirection: 'column',
      textAlign: ['', '', 'center'],
      alignItems: ['center'],
      justifyContent: 'center',
      zIndex: '100'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      marginBottom: 3,
      fontSize: ['2rem', '', '5rem'],
      textAlign: 'center'
    },
    subtitle: {
      marginBottom: 3,
      fontSize: 4,
      fontWeight: 'normal',
      textAlign: 'center'
    },
    text: {
      zIndex: '100',
      fontSize: [3, 3, 3, 3],
      lineHeight: '1.75',
      marginY: 3,
      textAlign: 'center',
      whiteSpace: 'break-spaces'
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================
    ctaButton: {
      display: 'flex',
      marginTop: '1rem',
      textAlign: 'center',
      padding: '1rem 3rem',
      backgroundColor: 'secondary',
      width: 'fit-content',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      // ?== Psuedo Elements ==
      '&:hover': {
        backgroundColor: 'primary',
        color: 'light'
      }
    }
  }
}
