const variantName = 'contentBoxesV3'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      padding: '3rem 1rem'
    },

    // ?============================
    // ?=========  Boxes  ==========
    // ?============================
    contentBoxes: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '0 auto'
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      width: ['calc(100% - 0.5rem * 2)', 'calc(50% - 0.5rem * 2)', 'calc(25% - 0.5rem * 2)'],
      margin: '0.5rem',
      borderStyle: 'solid 1px',
      borderColor: 'grey',
      borderRadius: '10px',
      overflow: 'hidden'
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    image: {
      marginBottom: 1,
      width: '100%'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================
    date: {
      marginBottom: 1,
      fontSize: 2,
      paddingX: 2,
      fontWeight: 'bold'
    },
    title: {
      marginBottom: 1,
      paddingX: 2
    },
    subtitle: {
      marginBottom: 1,
      paddingX: 2
    },
    text: {
      marginBottom: 1,
      paddingX: 2
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaLink: {
      padding: 3,
      backgroundColor: 'primary',
      margin: '0.5rem auto 0.5rem 0.5rem',
      borderRadius: '5px',
      color: 'light'
    }
  }
}
