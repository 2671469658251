const variantName = 'navigationV4a'

export default {
  [variantName]: {
    container: {
      position: 'fixed',
      width: '100%',
      height: ['', '', '120px'],
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      // height: ['75px', '75px', '75px', 'unset'],
      transition: 'all ease-in-out 0.4s',
      padding: ['0.5rem', '', '', '2rem 1rem'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: 'flex-end',
      background: 'linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.33) 50%, rgba(255,255,255,0) 100%)',
      color: 'light',
      '.seperatorLine': {
        margin: '1rem auto 1rem',
        width: '100%',
        display: 'none'
        // maxWidth: '200px',
      },
      '.socialIconsContainerDesktop': {
        svg: {
          path: {
            fill: 'white'
          }
        }
      }
    },
    containerScrolled: {
      variant: `${variantName}.container`,
      height: ['90px', '90px', '90px', 'unset'],
      padding: ['0.5rem', '', '', '1rem'],
      background: 'unset',
      backgroundColor: 'background',
      borderBottom: '2px solid',
      borderColor: 'primary',
      color: 'light',
      '.socialIconsContainerDesktop': {
        svg: {
          path: {
            fill: 'light'
          }
        }
      },
      '.hamburger > div': {
        backgroundColor: 'light'
      }
    },

    logoLocationContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0.25rem 1rem',
      height: '100%',
      position: ['', '', '', 'absolute'],
      left: ['', '', '', '50%'],
      transform: ['', '', '', 'translateX(-50%)'],
      textAlign: 'center',
      display: ['inline-block', '', '', '']
    },

    locationSwitcher: {
      backgroundColor: '',
      zIndex: '2000',
      alignSelf: 'flex-start',
      button: {
        backgroundColor: 'unset',
        borderTop: '1px solid',
        borderRadius: '0px'
      },
      '.popUp': {
        transform: ['', '', '', 'translateX(-45%)']
      },
      '.popUp .content': {
        backgroundColor: 'rgb(255 255 255 / 74%)'
      }
    },

    logo: {
      marginBottom: '0rem',
      zIndex: '2000',
      transition: 'all ease-in-out 0.4s',
      justifyContent: 'center',
      marginTop: ['', '', '', '1.25rem'],
      img: {
        // filter: 'brightness(0) invert(1)',
        maxHeight: ['80px', '', '120px']
      }
    },
    logoScrolled: {
      variant: `${variantName}.logo`,
      maxHeight: ['100%'],
      height: ['100%'],
      display: ['inline-block', '', '', 'inline'],
      textAlign: 'left',
      marginTop: ['', '', '', '0rem'],
      img: {
        // filter: 'brightness(0) invert(1)',
        height: ['100%'],
        maxHeight: ['100%', '60px'],
        padding: 0
      }
    },

    hamburger: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', ''],
      zIndex: '2600',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: ['0 0.5rem 0 auto', '', '', '0rem 0.75rem'],
      minWidth: '30px',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '3px',
        width: '100%',
        backgroundColor: 'secondary'
      }
    },
    hamburgerOpen: {
      variant: `${variantName}.hamburger`,
      height: 'unset',
      div: {
        position: 'absolute',
        boxShadow: 'none',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px'
      },
      '& > div:nth-of-type(1)': {
        transform: 'rotate(135deg) '
      },
      '& > div:nth-of-type(2)': {
        transform: 'rotate(-135deg)'
      }
    },

    navMenu: {
      position: ['absolute', 'absolute', 'absolute'],
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      height: ['100vh', '100vh', '100vh', 'unset'],
      backgroundColor: ['background'],
      top: '0px',
      zIndex: '2500',
      width: ['90%', '90%', '70%', '40%'],
      height: '0vh',
      minWidth: '250px',
      top: '-100vh',
      right: '0px',
      transition: 'all ease-in-out 1s',
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      textAlign: 'center',
      display: 'flex',
      flexDirection: ['column', 'column', 'column'],
      overflow: 'hidden',
      listStyleType: 'none',
      borderLeft: '3px solid #615050',
      '.socialIconsContainer': {
        svg: {
          path: {
            fill: 'light'
          }
        }
      },

      '.navMenuLogo': {
        backgroundColor: 'black',
        padding: '1rem',
        width: 'fit-content',
        img: {
          maxHeight: ['60px', '', '80px', '']
          // filter: 'brightness(0) invert(1)'
        }
      },

      '.navItem': {
        display: 'flex',
        width: '100%',
        borderBottom: '1px dashed white',
        a: {
          display: 'flex',
          alignItems: 'center',

          '::before': {
            content: "'🔥'",
            marginRight: '0.5rem',
            color: 'primary'
          },
          padding: '0.5rem 1.5rem',
          fontSize: ['1.5rem', '1.75rem', '2rem'],
          fontWeight: 'normal',
          fontFamily: 'heading',
          ':hover': {
            color: 'secondary'
          }
        }
      }
    },

    // when nav is open it uses this variant
    navMenuOpen: {
      variant: `${variantName}.navMenu`,
      right: '0%',
      top: '0%',
      height: '100vh',
      '.navItemDropdown': {
        width: '100%'
      }
    },

    navItem: {
      zIndex: '100',
      a: {
        fontSize: ['1.75rem'],
        padding: '0.5rem',
        textTransform: 'uppercase',
        fontWeight: '400',
        letterSpacing: '2px',
        textDecoration: 'none',
        ':hover': {
          color: 'primary'
        }
      }
    },

    smallNavMenu: {
      display: ['none', '', '', 'flex'],
      '.navItemDropdownMenu': {
        position: ['', '', '', 'absolute']
      }
    },
    phoneContainerDesktop: {
      display: ['none', '', '', 'block'],
      borderRight: 'solid 1px',
      borderColor: 'secondary',
      paddingRight: '0.75rem',
      a: {
        transition: 'all ease-in-out 0.5s',
        fontSize: ['1.75rem'],
        ':hover': {
          color: 'secondary',
          fontSize: '1.1rem'
        }
      }
    },

    socialIconsContainerDesktop: {
      display: ['none', '', '', 'flex'],
      padding: '0.5rem 1rem',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      margin: ['', '', '', '0 auto 0 0'],
      a: {
        display: 'flex',
        textAlign: 'center',
        padding: '0.5rem',
        alignItems: 'center',
        transition: 'all ease-in-out 0.5s',
        ':hover': {
          transform: 'scale(1.5)',
          svg: {
            path: { fill: 'secondary' }
          }
        }
      }
    },

    smallNavHeading: {
      fontSize: ['1.25rem', '1.75rem', '2.5rem'],
      marginBottom: '0.5rem',
      fontWeight: 'normal',
      fontFamily: 'heading'
    },

    phoneSocialContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
      margin: 'auto 0 0'
    },

    phoneContainer: {
      padding: ['0.5rem 2rem', '0.75rem 2rem', '2rem'],
      width: '100%',
      backgroundColor: 'primary',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      textAlign: 'left',
      a: {
        display: 'flex',
        padding: '0.5rem',
        fontSize: '0.8rem',
        svg: {
          marginRight: '0.25rem'
        }
      }
    },

    socialIconsContainer: {
      padding: ['0.5rem 2rem', '0.75rem 2rem', '2rem'],
      width: '100%',
      backgroundColor: 'secondary',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      textAlign: 'left',
      a: {
        display: 'flex',
        padding: '0.5rem',
        fontSize: '0.8rem',
        svg: {
          marginRight: '0.25rem'
        }
      }
    },

    // ?==========================
    // ?====  Dropdown Styles ====
    // ?==========================

    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      position: 'relative',
      cursor: 'pointer'
    },

    subNavItemDropdown: {
      variant: `${variantName}.navItem`,
      padding: '0.5rem',
      margin: ['0rem', '0rem', '0rem', '0rem'],
      whiteSpace: 'nowrap'
    },

    navItemDropdownMenu: {
      padding: '0.5rem',
      textShadow: '0px 0px 30px rgb(122 122 122 / 79%)',
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      flexDirection: 'column',
      width: 'fit-content',
      backgroundColor: 'background'
    },

    navBlock: {
      display: 'flex',
      backgroundColor: 'rgb(28 2 2 / 83%)',
      position: 'absolute',
      margin: '0rem',
      zIndex: '500',
      transition: 'all ease-in-out 1s',
      top: '0rem',
      left: '-200%',
      width: ['60vw'],
      height: ['100vh', '100vh', '100vh', '100vh']
    },
    navBlockOpen: {
      variant: `${variantName}.navBlock`,
      left: '0rem',
      top: '0rem',
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      backgroundRepeat: 'no-repeat'
    }
  }
}
