export default {
  primary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    borderRadius: '20px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    fontWeight: 'bold',
    ':hover': {
      backgroundColor: 'secondary'
    }
  },
  secondary: {
    backgroundColor: 'secondary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    ':hover': {
      backgroundColor: 'primary',
      color: 'light'
    }
  }
}
